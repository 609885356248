ol{
    margin:0;
    padding:0
}
table td,table th{
    padding:0
}
.c11{
    margin-left:36pt;
    padding-top:0pt;
    padding-bottom:0pt;
    line-height:1.15;
    page-break-after:avoid;
    orphans:2;
    widows:2;
    text-align:center;
    margin-right:36pt;
    height:0pt
}
.c30{
    padding-top:18pt;
    padding-bottom:6pt;
    line-height:1.15;
    page-break-after:avoid;
    orphans:2;
    widows:2;
    text-align:left;
    margin-right:36pt;
    height:12pt
}
.c0{
    margin-left:72pt;
    padding-top:0pt;
    padding-bottom:0pt;
    line-height:1.15;
    orphans:2;
    widows:2;
    text-align:left;
    margin-right:36pt;
    height:11pt
}
.c2{
    -webkit-text-decoration-skip:none;
    color:#000000DE;
    font-weight:500;
    text-decoration:underline;
    vertical-align:baseline;
    text-decoration-skip-ink:none;
    font-size:12pt;
    font-family:"Helvetica Neue";
    font-style:normal
}
.c8{
    margin-left:37pt;
    padding-top:0pt;
    padding-bottom:8pt;
    line-height:1.15;
    orphans:2;
    widows:2;
    text-align:left;
    margin-right:0pt
}
.c7{
    margin-left:0pt;
    padding-top:0pt;
    padding-bottom:8px;
    line-height:1.45;
    orphans:2;
    widows:2;
    text-align:left;
    margin-right:0pt
}
.c9{
    padding-top:0pt;
    padding-bottom:0pt;
    line-height:1.15;
    orphans:2;
    widows:2;
    text-align:left;
    margin-right:36pt;
    height:11pt
}
.c3{
    padding-top:0pt;
    text-indent:0pt;
    padding-bottom:0pt;
    line-height:1.15;
    orphans:2;
    widows:2;
    text-align:left;
    margin-right:36pt
}
.c41{
    padding-top:0pt;
    padding-bottom:0pt;
    line-height:1.15;
    page-break-after:avoid;
    orphans:2;
    widows:2;
    text-align:center
}
.c1{
    color:#000000DE;
    font-weight:400;
    text-decoration:none;
    vertical-align:baseline;
    font-size:12pt;
    font-family:"Helvetica Neue";
    font-style:italic
}
.c10{
    color:#000000DE;
    font-weight:400;
    text-decoration:none;
    vertical-align:baseline;
    font-size:11pt;
    font-family:"Helvetica Neue";
    font-style:normal
}
.c46{
    -webkit-text-decoration-skip:none;
    color:#000000DE;
    font-weight:400;
    text-decoration:line-through;
    vertical-align:baseline;
    text-decoration-skip-ink:none;
    font-family:"Helvetica Neue"
}
.c4{
    color:#000000DE;
    font-weight:400;
    text-decoration:none;
    vertical-align:baseline;
    font-size:12pt;
    font-family:"Helvetica Neue";
    font-style:normal
}
.c13{
    color:#000000DE;
    font-weight:700;
    text-decoration:none;
    vertical-align:baseline;
    font-family:"Helvetica Neue";
    font-style:normal
}
.c44{
    padding-top:12pt;
    padding-bottom:12pt;
    line-height:1.15;
    orphans:2;
    widows:2;
    text-align:left
}
.c51{
    color:#000000DE;
    font-weight:300;
    text-decoration:none;
    vertical-align:baseline;
    font-size:16pt;
    font-family:"Lato"
}
.c21{
    padding-top:0pt;
    padding-bottom:0pt;
    line-height:1.15;
    orphans:2;
    widows:2;
    text-align:left
}
.c52{
    color:#000000DE;
    font-weight:400;
    text-decoration:none;
    vertical-align:baseline;
    font-family:"Times New Roman"
}
.c54{
    color:#000000DE;
    font-weight:400;
    vertical-align:baseline;
    font-size:17pt;
    font-family:"Helvetica Neue"
}
.c49{
    color:#000000DE;
    font-weight:400;
    text-decoration:none;
    vertical-align:baseline;
    font-family:"Helvetica Neue"
}
.c15{
    font-weight:400;
    text-decoration:none;
    vertical-align:baseline;
    font-family:"Helvetica Neue"
}
.c36{
    color:#000000DE;
    text-decoration:none;
    vertical-align:baseline;
    font-family:"Helvetica Neue"
}
.c23{
    text-decoration:none;
    vertical-align:baseline;
    font-family:"Helvetica Neue";
    font-style:normal
}
.c25{
    padding-top:3pt;
    padding-bottom:0pt;
    line-height:1.0;
    text-align:left
}
.c57{
    color:#000000DE;
    text-decoration:none;
    vertical-align:baseline;
    font-family:"Times New Roman"
}
.c50{
    text-decoration-skip-ink:none;
    -webkit-text-decoration-skip:none;
    text-decoration:underline
}
.c53{
    background-color:#ffffff;
    max-width:468pt;
    padding:58.5pt 72pt 49.5pt 72pt
}
.c12{
    font-style:italic;
    color:#ec7575
}
.c16{
    color:#852828;
    font-weight:700
}
.c18{
    color:#c78cbb;
    font-weight:700
}
.c22{
    color:#a64d79;
    font-weight:700
}
.c20{
    color:inherit;
    text-decoration:inherit
}
.c55{
    color:#000000DE;
    font-size:15pt
}
.c48{
    color:#000000DE;
    font-size:6pt
}
.c19{
    color:#565994;
    font-weight:700
}
.c6{
    color:#ff9900;
    font-weight:700
}
.c26{
    color:#ec7575
}
.c40{
    margin-right:0pt;
    line-height:1.45;
}
.c24{
    font-weight:700
}
.c58{
    text-decoration:none
}
.c39{
    margin-left:0pt
}
.c42{
    color:#ff9900
}
.c28{
    font-size:11pt
}
.c38{
    text-indent:36pt
}
.c32{
    color:#c78cbb
}
.c47{
    color:#0a0a0a
}
.c34{
    font-style:normal
}
.c5{
    font-size:12pt
}
.c45{
    margin-left:36pt
}
.c43{
    color:#565994
}
.c27{
    color:#852828
}
.c35{
    color:#a64d79
}
.c37{
    text-indent:-36pt
}
.c17{
    font-style:italic
}
.c33{
    color:#663782
}
.c29{
    color:#3c7383
}
.c56{
    color:#9900ff
}
.c31{
    color:#cc0000
}
.c14{
    height:8pt
}
.title{
    padding-top:0pt;
    color:#000000DE;
    font-size:26pt;
    padding-bottom:3pt;
    font-family:"Helvetica Neue";
    line-height:1.15;
    page-break-after:avoid;
    orphans:2;
    widows:2;
    text-align:left
}
.subtitle{
    padding-top:0pt;
    color:#666666;
    font-size:15pt;
    padding-bottom:16pt;
    font-family:"Helvetica Neue";
    line-height:1.15;
    page-break-after:avoid;
    orphans:2;
    widows:2;
    text-align:left
}
li{
    color:#000000DE;
    font-size:11pt;
    font-family:"Helvetica Neue"
}
p{
    margin:0;
    color:#000000DE;
    font-size:11pt;
    font-family:"Helvetica Neue"
}
h1{
    padding-top:20pt;
    color:#000000DE;
    font-size:20pt;
    padding-bottom:6pt;
    font-family:"Helvetica Neue";
    line-height:1.15;
    page-break-after:avoid;
    orphans:2;
    widows:2;
    text-align:left
}
h2{
    padding-top:18pt;
    -webkit-text-decoration-skip:none;
    color:#000000DE;
    text-decoration:underline;
    font-size:12pt;
    padding-bottom:6pt;
    line-height:1.15;
    page-break-after:avoid;
    text-decoration-skip-ink:none;
    font-family:"Helvetica Neue";
    orphans:2;
    widows:2;
    text-align:center
}
h3{
    padding-top:16pt;
    color:#434343;
    font-size:12pt;
    padding-bottom:4pt;
    font-family:"Helvetica Neue";
    line-height:1.15;
    page-break-after:avoid;
    orphans:2;
    widows:2;
    text-align:left
}
h4{
    padding-top:12pt;
    color:#666666;
    font-size:12pt;
    padding-bottom:4pt;
    font-family:"Helvetica Neue";
    line-height:1.15;
    page-break-after:avoid;
    orphans:2;
    widows:2;
    text-align:left
}
h5{
    padding-top:12pt;
    color:#666666;
    font-size:11pt;
    padding-bottom:4pt;
    font-family:"Helvetica Neue";
    line-height:1.15;
    page-break-after:avoid;
    orphans:2;
    widows:2;
    text-align:left
}
h6{
    padding-top:12pt;
    color:#666666;
    font-size:11pt;
    padding-bottom:4pt;
    font-family:"Helvetica Neue";
    line-height:1.15;
    page-break-after:avoid;
    font-style:italic;
    orphans:2;
    widows:2;
    text-align:left
}